// module
import { useEffect, useState } from "react";
import {  useNavigate, useParams } from 'react-router-dom';


import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm, NumberFormat } from '../../services/srvc-utilities'

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";
import { ContactSearch } from "../../services/srvc-users-realm";
import TransferMemberSearchList from "../webx/transfer-member-search-list";
import TransferMembersDelete from "../webx/transfer-memeber-delete";
import { AssetsDetails } from "../../services/srvc-assets-realm";
import { AccountsMinterBalance } from "../../services/srvc-accounts-minter-realm";
import { AssetsTransferCreate } from "../../services/srvc-transfers-assets-realm";
import { TokensAssetBalance } from "../../services/srvc-tokens-realm";


const list = (require("../../data.static/data-select-vault.json")).data.filter(item => item.actv);

export default function AssetsTransferModule() {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [form, setForm] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [input, setInput] = useState('')
  const [credit, setCredit] = useState([])
  const [contactList, setContactList] = useState([])
  const [balance, setBalance] = useState({number:'0',ticker:'STX'})
  const [assx, setAssx] = useState('')
  const [disable, setDisable] = useState(false)

  const [memo, setMemo] = useState('');
  const [data, setData] = useState({ 
    size:'',   
    user: '',
    number: ''
  })


  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        var res = await AssetsDetails({data:{user:asset.item}, srvc:''})
        if(res.stat){ setAssx(res.data.item)}
        var bal = await TokensAssetBalance({data:{user:asset.item, asset: res.data.item }, srvc:''})
        // console.log(bal?.data?.balance?.number)
        if(bal.stat) setBalance(bal?.data?.balance)
        setLoader(false);
      }
      fetchData()
    } else {}
  },[asset.item]);



  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async () => {
    setLoader(true)
    setSubmit(true)
    var datx = {
          "cred":credit[0],
          "debt":{
              "item":asset.item,
              "mail":asset.mail,
              "name":asset.name
          },
          "asset":assx,
          "unit":assx,
  
          "meta":{
              "name":"asset transfer"
          },
          "sale":{"number":data.size, "ticker":"STX"},
          "rate":{number:data.number, ticker: "INR" }
      }
  
      var res = await AssetsTransferCreate({data:datx, srvc:''})
      if(res.stat){
        setLoader(false)
        setMemo("Transfer Under Processing !")
      }
      else{
        setLoader(false)
        setMemo("Error: Transfer failed !")
      }
  
  
  
  }

  useEffect(()=>{
    setContactList([])
    setMemo('')
    setCredit([])

  },[input])

  const SearchContact = async()=>{
    if(input=="" || !input)
    {
      setMemo("Please provide Valid search input !")
      return
    }
    setContactList([])
    setLoader(true)

    var datx = {
      team: asset.item,
      search:{ search: input }
    }
    var res = await ContactSearch({data: datx, srvc:''})
    // console.log(res)
    if(res.stat){
       setContactList(contactList => res?.data?.list) 
       if(res.data.list.length == 0){ setMemo("No Contact Found !")}
      }
    setLoader(false)
  }

  const SetMembers = async(mmbx) => {
    // console.log(mmbx)
    setCredit([{name: mmbx.user.name, mail: mmbx.user.mail, item: mmbx.user.item}])
    

  }

  const ResetMembers = async(sort) => {
    setCredit([])
    setInput('')    
  }
  
  useEffect(()=>{
    if(data.size> parseFloat(balance.number))
    {
      setDisable(true)
    }
    else{
      setDisable(false)
    }

  },[data])


  // if (loader){ return ( <> <WebbLoaderSmall /> </> ) }

  return (
    <div className="">
      
      {/* info */}
      <p className="m-0">Please enter User Details, Number of Units and Transfer Rate</p>
      <WebbDividerMedium />

      <div className="border p-3 d-flex bg-white rounded-xd">
        <div className="me-auto">Available Units:</div>
        <div className="text-small fw-bold">{NumberFormat(parseFloat(balance?.number) || '0', "w", 2)} {balance?.ticker}</div>
      </div>
      <WebbDividerMedium />
      {/* data */}
      <div className="">

        <div className={"form-group mb-3"}>
          <div className={credit.length>0 ? "d-none":""}>
            
            <label className="form-label small">Search User <FormNeeded/></label>
            <div className="">
            <input value={input} onChange={(e)=> setInput(e.target.value)} className="form-control " style={{height:'40px'}} />
            <i  onClick={()=>SearchContact() } class='bx bx-search-alt-2  cursor float-end' style={{fontSize:'28px', marginTop:'-32px'}}></i>
            </div>
            <div className={loader? "text-center mt-3" : 'd-none'}>
              <WebbLoaderSmall />
            </div>
            
            <div className={!loader  && contactList.length>0 ? 'mt-3':'d-none'}>
            <TransferMemberSearchList data={contactList} load={loader} item={SetMembers}/>
            </div>

          </div>
          
          {
            !loader && credit.length>0 ?
            <>
              <div className={!loader  && credit ? 'mt-3':'d-none'}>
                <TransferMembersDelete data={credit} item={ResetMembers} />
              </div>
            </> :
            <>
            </>
          }
         
        </div>

        <div className={credit.length>0 ? "form-group mb-3 " : 'd-none'}>
          <label className="form-label small">Number of Units <FormNeeded/></label>
          <input type="text" className="form-control rounded-none"
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.size}
            onChange={({ target }) => {handleChange("size", target.value);}}
            placeholder="0123">
          </input>
        </div>
  
        <div className={credit.length>0 ? "form-group mb-3 " : 'd-none'}>
          <label className="form-label small">Unit Rate <FormNeeded/></label>
          <input type="text" className="form-control rounded-none"
            style={{fontSize:'0.9rem', height:'2.7rem'}}
            value={data.number}
            onChange={({ target }) => {handleChange("number", target.value);}}
            placeholder="0123">
          </input>
        </div>
      </div>
  
      <div className={submit && loader? "text-center mt-3" : 'd-none'}>
      <WebbLoaderSmall />
      </div>
  
      <WebbDividerMedium />
      <div className="mx-3">
        <p className="m-0">{memo}</p>
      </div>
  
      {/* <WebbDividerMedium /> */}
      <div className={submit || !credit.length>0 ? 'd-none' : 'mx-3'}>
        {/* <WebbDividerMedium /> */}
        <div className="d-flex justify-content-between">
  
          <button className={`btn btn-light border back-color-wite rounded-xx text-small`}
            type="button"
            disabled={loader || submit}
            onClick={()=> { navigate(`/${asset.role}/auth/next`)}}
          >{loader ? 'Please Wait...' : 'Cancel'}</button>
  
          <button className={`btn btn-success border-none rounded-xx text-small`}
            type="button"
            disabled={  submit || disable}
            onClick={()=> { handleSubmit()}}
          >{loader ? 'Please Wait...' : 'Continue'}</button>
  
        </div>
  
  
      </div>
    
      <WebbDividerMedium />
      <WebbDividerMedium />
    </div>
    )
  }