import axios from "axios";
import services from '../data.services/data-services-main.json'

const base = (services.data.find(x => x.code === 'contracts')).link

// -----------------

export const ContractCreate = async (item) => {
  
  const basx = base + '/contract/create';
  const head = { 
    "Content-Type": "application/json",
    "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}

  var result;
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const ContractList = async (item) => {
  
    const basx = base + '/contract/list/users';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

export const ContractDetails = async (item) => {
  
    const basx = base + '/contract/details';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const ContractDocumentList = async (item) => {
  
    const basx = base + '/contract/documents/list';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const CreateContractDocument = async (item) => {
  
    const basx = base + '/document/create';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const DocumentFileAdd = async (item) => {
  
    const basx = base + '/document/add/file';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }


  export const ContractMembersSet = async (item) => {
  
    const basx = base + '/contract/members/set';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const ContractDocumentDetails = async (item) => {
  
    const basx = base + '/contract/document/details';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const ContractSendForEsignature = async (item) => {
  
    const basx = base + '/contract/send/esign';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }

  export const UserContractDocumentsList = async (item) => {
  
    const basx = base + '/documents/list/user';
    const head = { 
      "Content-Type": "application/json",
      "Authorization": process.env.REACT_APP_WEBB_SITE_CLNT
    }
    const datx = {data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC}
  
    var result;
    try {
      result = await axios.post(basx, datx, { headers: head })
      return { code: result.status, ...result.data }
    } catch (error) {
      return { code: error.response.status, ...error.response.data }
    }
  }



  