// main
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useNavigate, useParams } from 'react-router-dom'

import ContentFormat from '../content/webz/content-format-xv'
import WebbHeader from '../content/webz/webb-header-navs-xv'

import WebbIcon from '../content/webx/webb-icon'
import UserAvatar from '../content/webx/user-avatar'
import WebbDateTime from '../content/webx/webb-datetime'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import HeaderSectionDark from '../content/webx/head-section-dark'

import { GetLocalBusiness, GetLocalUser } from '../services/srvc-auth-local'
import { GetUserForm } from '../services/srvc-utilities'


import ContractViewDetailsModule from '../content/contract/contract-view'
import ContractOverview from '../content/webx/contract-overview'
import { ContractDetails, ContractDocumentList } from '../services/srvc-contract-realm'
import WebbLoaderSmall from '../content/webx/webb-loader-sm'
import ContractActions from '../content/contract/contract-actions'
import BackButton from '../content/webx/button-back'

export default function ContractView () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const { id } = useParams();

  const navigate = useNavigate()

  const [info, setInfo] = useState({});
  const [mmbr, setMembers] = useState([]);
  const [file, setFiles] = useState([]);
  const [Pendingfiles, setPendingFiles] = useState([])
  const [canceledfiles, setCanceledfiles] = useState([])
  const [kycfiles,setKycfiles] = useState([])
  const [draftfiles,setDraftfiles] = useState([])
  const [draft,setDraft] = useState(false)
  const [completed, setCompleted] =useState(true)
  const [cancelled,setCancelled] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [sort, setSort] = useState('')
  const [loading, setLoading] = useState(true)

  const metadata = {
    name: 'Contracts',
    banner: { link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }


  const handleSort = async(item) => {
    // console.log('item: ', item)
    setSort(item);
  }

  useEffect( () => {
    setLoading(true)
    if (asset){
      const fetchData = async() => {
        // info
        var res = await ContractDetails({data:{smart: id}, srvc:''})
        // console.log(res)
        if(res.data)
          {
            setInfo(res.data);
            var mmbx =res.data.members
            setMembers(mmbx)
          }


        // files
        var documents =await ContractDocumentList({data: {smart:id}, srvc:''})
        if(documents.stat) documents = documents.data
        // console.log(documents) 
        if(documents.length>0)
        {
          const completed_docs = documents.filter(function(item){ return item.stat=="6"})
          setFiles(completed_docs);
  
          const pending_files = documents.filter(function(item){ return item.stat=="2" && item.actv==true})
          // console.log(pending_files)
          setPendingFiles(pending_files)
  
          const draft_files = documents.filter(function(item){ return item.stat=="0" && item.actv==true})
          // console.log(pending_files)
          setDraftfiles(draft_files)
  
          var cancelled_files = documents.filter(function(item){ return item.stat=="11" && item.actv==false})
          setCanceledfiles(cancelled_files)

        }              

        const datax = {
          data: { document: id },
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  
  // if (loading){ return ( <>  </> ) }

  return (
    <>
      <Helmet>
        <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: 'xtra', show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon data={{ color: 'text-color-main', size: 'text-icon-md' }} />
              </Link>
            </>
          ),
          data: ( <> <WebbHeader /> </> ),
          footer: ( <> <UserAvatar /> </> )
        }}
        sidebar={{
          header: ( <> <p className='text-normal text-bold m-0'>{metadata.name}</p> </>),
          data: (
            <>
            <HeaderSectionDark data={{name: 'Overview'}}/>
            {
              loading ? 
              <>
              <WebbLoaderSmall />
              </>
              :
              <>
              <ContractOverview data={info}/>
              </>
            } 
            

            </>
          ),
          footer: <><WebbDateTime /></>
        }}
        content={{
          header: ( <> <p className='m-0 '> <BackButton /> Contract Details</p> </> ),
          data: (
            <>
              <h2 className='text-normal text-color-next mx-3 mb-2 d-none'> </h2>
              {/* <HeaderSectionDark data={{name: 'Documents'}}/> */}
              <ContractViewDetailsModule />
            </>
          )
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: ( <> 
            {
              loading ? 
              <>
              <WebbLoaderSmall />
              </>
              :
              <>
               <ContractActions data={{docx: info, mmbr: mmbr.length, file: draftfiles.length}}/>
              </>
            }
           
          </> ),
          footer: <></>
        }}
      />
    </>
  )
}
