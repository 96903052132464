// main
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xx";
import WebbHeader from "../content/webz/webb-header-xx";

import WebbDividerMedium from "../content/webx/webb-divider-md";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-local";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import ContractsEditMembersModule from "../content/contract/contract-edit-mmbr";

export default function ContractsEditMembersView() {
  const { id } = useParams();
  const metadata = {
    name: "Edit Members",
    banner: {
      link: "https://img.freepik.com/premium-photo/metaverse-city-cyberpunk-concept-3d-render_84831-1036.jpg?w=900",
    },
  };

  const listTabs = [
    { name: "Contracts", code: "contracts" },
    // {name: 'E-Stamp', code: 'estm'}
  ];


  const navigate = useNavigate();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);

  const [search, setSearch] = useState();

  const handleSearch = async (item) => {
    // console.log('srch: ', sort)
    setSearch(item);
  };

  // useEffect(() => {
  //   if (asset) {
  //     const fetchData = async () => {
  //       setLoading(true);

  //       var res = await ContractDetails({ data: { item: id }, srvc: "" });
  //       // console.log(res)
  //       setData(res.data);

  //       setLoading(false);
  //     };
  //     fetchData();
  //   } else {
  //     navigate("/");
  //   }
  // }, [id]);

  //if(loading) {return(<div className={loading ? '' : 'd-none'}>Please wait...</div>)}

  return (
    <>
      
      <Helmet>
        <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata?.name}
        header={{
          size: "small",
          show: true,
          data: (
            <>
              <div className="sticky-top">
                <WebbHeader data={{ home: "/", name: metadata.name, link:'home' }} />
              </div>
            </>
          ),
        }}
        media={{
          size: "xtra",
          show: false,
          link: metadata.banner.link,
          memo: metadata.memo,
          data: (
            <>
              <WebbDividerMedium />

              <div className="container text-color-wite">
                <h1 className="text-lead text-bold mx-3">
                  {metadata?.name}
                </h1>
              </div>

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerSmall />
            </>
          ),
        }}
        content={{
          size: "small",
          show: true,
          data: (
            <>
              <WebbDividerMedium />
             
              <ContractsEditMembersModule />
              <WebbDividerSmall />
              
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        footer={{
          size: "xtra",
          show: true,
          data: (
            <>
              <div className="back-color-lite text-color-tone">
                <WebbDividerSmall />
                <div className="container">{/* <WebbFooterBase /> */}</div>
                <WebbDividerSmall />
                {/* <WebbFooterMobile /> */}
              </div>
            </>
          ),
        }}
      ></ContentFormat>
    </>

  );
}
