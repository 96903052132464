// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { NumberFormat, CardNumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbIcon from "../webx/webb-icon";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

// import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";

// const media = 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'
// const media = 'https://img.freepik.com/free-photo/blockchain-technology-background-gradient-blue_53876-124646.jpg?w=300'
const media =
  "https://img.freepik.com/free-vector/topographic-map-background-concept_23-2148588244.jpg?w=826";

export default function TeamInfocardHomeModule () {

  const navigate = useNavigate();
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  // console.log(asset)
  
  const [loader, setLoader] = useState(true);

  const [balance, setBalance] = useState({number: '000000', ticker: '******'})
  const [data, setData] = useState()

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        // const result = await AccountsBaseBalance({data: {user: asset.item},service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret})
        // console.log (result) 

        // if (result.stat) {
        //   setBalance(result.data.balance)
        //   setData(result.data)
        // }


        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  // if (loader) return <WebbLoaderSmall/>


  return (
    <>
      {/* info */}

      {/* data */}
      <div
        className="rounded-xd text-color-wite"
        style={{
          backgroundImage: `url(${media})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundColor: "rgba(0, 0, 0, 0.75)",
          // minHeight: '100vh'
          // height:'100vh'
        }}
      >
        <div
          className="rounded-xd p-3"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.33)" }}
        >
          <div className="d-flex justify-content-between" style={{}}>
            <div className="" style={{ height: "2.5rem", width: "2.5rem" }}>
              {/* <Jazzicon diameter={33} seed={jsNumberForAddress(asset.item ??= Date.now().toString())} />  */}
              <WebbIcon
                data={{ color: "text-color-wite", size: "text-icon-md" }}
              />
            </div>

            <div className="text-end">
              <div className="mt-1"></div>
              <div
                className="back-color-wite rounded-xx m-0 p-0 text-center"
                style={{ height: "1.1rem", width: "1.1rem" }}
              >
                <i
                  className={`bx bxs-circle text-normal text-color-${
                    asset?.active ? "success" : "error"
                  }`}
                ></i>
              </div>
            </div>
          </div>

          <WebbDividerSmall />
          {/* <div className="mb-2"></div> */}
          <p className="text-lead text-bold text-sm m-0">
            {CardNumberFormat(asset?.number || "9999000000000456")}
          </p>

          <WebbDividerSmall />
          <div className="mb-1"></div>
          <p className="text-normal text-sm text-bold m-0">
            {asset?.name || "******"}
          </p>
          <p className="text-small text-sm m-0">{asset?.mail || "******"}</p>
        </div>
      </div>
      <div className="back-color-wite mx-3 d-none">
        <p className="text-small m-0 p-1 px-2">
          Status: {asset?.active ? "Active" : "Inactive"}
        </p>
      </div>

      <WebbDividerSmall />
      <div className="mx-3 d-none">
        <p className="text-small m-0">
          Status: {asset?.active ? "Active" : "Inactive"}
        </p>
        {/* {asset?.item || '******'} */}
      </div>

      <WebbDividerSmall />
      <div className="back-color-wite p-3 rounded-xd border d-none">
        <p className="text-small m-0">Account Balance</p>
        <p className="m-0">
          <span className="text-lead text-bold">
            {NumberFormat(balance?.number / 1000000 || "0", "", "2")}
          </span>
          <span className="ms-1"></span>
          <span className="text-small">{balance?.ticker || "***"}</span>
        </p>
      </div>
    </>
  );
}