
// documents
import { useEffect, useState } from "react";
import { useHistory, useNavigate, useParams } from 'react-router-dom';



import { toBase64, GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

// import { ftos } from "../../services/srvc-utilities";
import FileSaveFirebase from "../../services/srvc-filesave-fbsx";
import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import FormNeeded from "../webx/form-needed";
import {  CreateContractDocument, DocumentFileAdd } from "../../services/srvc-contract-realm";


export default function ContractsNewFileModule() {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading]=useState(false);
  const [form, setForm] = useState(false);

  const [info, setInfo] = useState({});
  const [data, setData] = useState({});
  const [base64, setBase64]=useState()
  const [link, setLink]=useState()

  const [name, setName] = useState('')
  const [memo, setMemo] = useState('')

  const [kycu,setKycu] = useState(false)
  const [kycb,setKycb] = useState(false)

 

  // form validation
  useEffect( () => {
    setForm(false)
    if (base64 && base64 !=='') setForm(true);
  },[base64]);

  const SetFileLink = async(link) => {
    // console.log (link);
    setLink(link)
    // handleChange("link", link);
  }

  const SetFileData = async(data) => {
    var str1=await toBase64(data);
    setBase64(str1)
  }

  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 

    var datx = {
          name: name,
          memo: memo,
          form: 'document',
          sort: '',
          nmbr: Date.now().toString(),
          smrt: id,
          stts: '',
          ents: '',
          user: asset.item ,
      }

      var res = await CreateContractDocument({data:datx, srvc:''})
    //   console.log(res)
      if(res.stat)
      {
            var datx = { document: res.data.docn, link: "", file: base64, mode: 'file', sort: 'init', kycu:false, kycb:false }
            var res = await DocumentFileAdd ({data: datx, srvc:''})
            // console.log(res)
            navigate(`/${asset.role}/contract/${id}`)
            setLoading(false);
      }
  
  }

  if (loading){ return ( <> <WebbLoaderSmall /> </> ) }

  return (
  <>
    {/* info */}
    <p className="text-color-tone">{id}</p>
    <WebbDividerSmall />

        <div className="mb-3 ">
          <div className="form-group">
            <label className="form-label small">Document Name <FormNeeded/></label>
            <input type="text" className="form-control rounded-none"
              style={{fontSize:'0.9rem', height:'2.7rem'}}
              value={name}
              onChange={({ target }) => {setName(target.value);}}
              placeholder="Document Name">
            </input>
          </div>
        </div>


        <div className="form-group mb-3">
          <label className="form-label small">Description <FormNeeded/></label>
          <textarea 
              className="form-control rounded-none" 
              style={{fontSize:'0.9rem'}}
              rows="3"
              value={memo}
              onChange={({ target }) => {setMemo(target.value);}}
              placeholder="Description">  
          </textarea>
        </div> 


    {/* action */}
    <div className="mb-3">
      <label className="form-label small">File Attachment (PDF) <span className="text-danger">*</span></label>
      <FileSaveFirebase name={id} link = {SetFileLink} file={SetFileData}/>    
    </div>

    {/* <WebbDividerMedium /> */}

    {/* <div className="bg-white p-3 border d-none">
      <div className="d-flex">
        <label className="me-auto">Individual eKYC</label>
        <input className="text-end" value={kycu} onChange={()=>setKycu(!kycu)} type="checkbox" />
      </div>

      <div className="d-flex">
        <label  className="me-auto">Business eKYC</label>
        <input className="text-end"  value={kycb} onChange={()=>setKycb(!kycb)} type="checkbox" />
      </div>
    </div>
     */}

    {/* form */}
    <WebbDividerMedium />
    <form className="" onSubmit={handleSubmit}>

      <div className="">
        <div className="d-grid">
          <button className={`btn height-md btn-success back-color-main rounded-none w-100`}
            disabled={!name || !memo || !base64}
          ><small>Save & Continue</small>
          </button>
        </div>
      </div>

    </form>
  
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}
