// network
import { useEffect, useState } from "react";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import FormNeeded from "../webx/form-needed";
import { ContactCreate } from "../../services/srvc-users-realm";
import { useNavigate } from "react-router-dom";
import WebbSpinText from "../webx/webb-spintext";

const list = require('../../data.static/data-select-network.json').data.filter(item=>item.actv)


export default function NetworkCreateModule() {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("")
  const [mail, setEmail] = useState("")
  const [mobile, setMobile] = useState("")
  const [role, setRole] = useState("")
  const [memo, setMemo] = useState()


  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.item]);

  const handleSubmit = async()=>{
    setLoading(true)
    var datx = {
        "team":asset.item,
        "user":{
            "name": name,
            "mail": mail,
            "mobile":mobile
        },
        "role": role
    }

    var res = await ContactCreate({data: datx, srvc:''})
    // console.log(res)
    if(res.stat) navigate(-1)
    else { setMemo(res.memo) }
    setLoading(false)


  }
  


  if (loading){ return ( <><WebbSpinText/> </> ) }

  return (
    
    <>
      <div className="rounded-wd back-color-wite border p-3">
        <div className="">
            <label className="text-small mb-2">Name</label><FormNeeded />
            <input value={name} onChange={(e)=> setName(e.target.value)} className="form-control" />

            <label  className="text-small mb-2 mt-4">Email</label><FormNeeded />
            <input  value={mail} onChange={(e)=> setEmail(e.target.value)} className="form-control" />

            <label  className="text-small mb-2 mt-2 mt-4">Mobile</label><FormNeeded />
            <input  value={mobile} onChange={(e)=> setMobile(e.target.value)} className="form-control" />

            <label  className="text-small mb-2 mt-2 mt-4">Choose Role</label><FormNeeded />
            <select onChange={(e)=> setRole(e.target.value)} className="form-select">
                <option>Choose</option>
                {
                    list && list.map(item=>{
                        return(
                            <>
                            <option value={item.name}>{item.name}</option>
                            </>
                        )
                    })
                }
            </select>
             <p className="mt-2 mb-2 text-danger">{memo}</p>
            <button onClick={()=> handleSubmit()} disabled={!name || !mail || !mobile || !role } className="btn btn-outline-primary mt-5 w-100">Submit</button>
        </div>

      </div>
    </>
    )
  }