// import React, { useEffect, useState } from "react";
// import { Doughnut } from "react-chartjs-2";
// import "chart.js/auto";
// import { GetUserForm } from "../../services/srvc-utilities";
// import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
// import { AssetsDetails } from "../../services/srvc-assets-realm";
// import { TokensListUser } from "../../services/srvc-tokens-realm";

// export default function SharesValuationCardModule() {
//   const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();

//   const [loading, setLoading] = useState(true);
//   const [data, setData] = useState([]);
//   const [assx, setAssx] = useState(null);
//   const [creatorShare, setCreatorShare] = useState();
//   const [sale, setSale] = useState(0);
//   const [available, setAvailable] = useState();
//   const [tokenList, setTokenList] = useState([]);

//   const [shares, setShares] = useState({});

//   useEffect(() => {
//     if (asset) {
//       setLoading(true);
//       const fetchData = async () => {
//         setLoading(true);
//         var res = await AssetsDetails({ data: { user: asset.item }, srvc: "" });

//         if (res.stat) {
//           setAssx(res.data);
//           var res = await TokensListUser({
//             data: { asset: res.data.item },
//             srvc: "",
//           });

//           setTokenList(res.data.list);
//           //console.log(res.data.list);

//           const filteredPeople = res.data.list.filter(
//             (person) => person.user.item != asset.item
//           );
//           setData(filteredPeople);

//           const availableShare = res.data.list.filter(
//             (person) => person.user.item == asset.item
//           );
//           setCreatorShare(availableShare);
//         } else {
//           setData([]);
//         }

//         // setData(list.data)
//         // setData(list.filter(item => item.user.includes(asset.form)))
//         //
//         // const sharex = list.data.reduce((a, x) => a + parseInt(x.sale.number), 0)
//         // setShares(sharex)

//         setLoading(false);
//       };
//       fetchData();
//     } else {
//     }
//   }, [asset.item]);

//   useEffect(() => {
//     if (assx && tokenList.length > 0) {
//       const otherslist = tokenList.filter(
//         (item) => item.user.type === "Others"
//       );
//       const sumOfUnitsForOthers = otherslist.reduce(
//         (sum, item) => sum + item.balance.number,
//         0
//       );

//       const founderslist = tokenList.filter(
//         (item) => item.user.type === "Founders"
//       );
//       const sumOfUnitsForFounder = founderslist.reduce(
//         (sum, item) => sum + parseInt(item.balance.number),
//         0
//       );

//       const Investorslist = tokenList.filter((item) => item.user.type === "Investors");
//       const sumOfUnitsForInvestors = Investorslist.reduce(
//         (sum, item) => sum + parseInt(item.balance.number),
//         0
//       );

//       const Mentorslist = tokenList.filter(
//         (item) => item.user.type === "Mentors"
//       );
//       const sumOfUnitsForMentors = Mentorslist.reduce(
//         (sum, item) => sum + parseInt(item.balance.number),
//         0
//       );

//       const Creatorlist = tokenList.filter(
//         (item) => item.user.type === "Creator"
//       );
//       const sumOfUnitsForCreator = Creatorlist.reduce(
//         (sum, item) => sum + parseInt(item.balance.number),
//         0
//       );
//       console.log("sumOfUnitsForFounder", sumOfUnitsForFounder);
//       const founderShares =
//         (parseFloat(sumOfUnitsForFounder) / parseFloat(assx.units.mint)) * 100;
//        console.log("founderShares", founderShares);
//       const mentorsShares =
//       (parseFloat(sumOfUnitsForMentors) / parseFloat(assx.units.mint)) * 100;
//       const InvestorsShares =
//         (parseFloat(sumOfUnitsForInvestors) / parseFloat(assx.units.mint)) * 100;
      
//       const creatorShare =
//         (parseFloat(sumOfUnitsForCreator) / parseFloat(assx.units.mint)) *
//         100;

//       // const creatorShare =
//       //   (parseFloat(creatorShare[0].balance.number) /
//       //     parseFloat(assx.units.mint)) *
//       //   100;

//       const othersShares =
//         100 -
//         (founderShares + mentorsShares + InvestorsShares + creatorShare );

//       const holding = {
//         creatorShare: creatorShare.toFixed(2),
//         founderShares: founderShares.toFixed(2),
//         mentorsShares: mentorsShares.toFixed(2),
//         investorShares: InvestorsShares.toFixed(2),
//         othersShares: othersShares.toFixed(2),
//       };
//       setShares(holding);
//     }
//   }, [assx, tokenList]);

//   const datas = {
//     datasets: [
//       {
//         data: [
//           shares?.founderShares,
//           shares?.investorShares,
//           // shares?.boardsShares,
//           shares?.mentorsShares,
//           shares?.othersShares,
//           shares?.creatorShare,
//         ], // Example data
//         backgroundColor: [
//           "#007500",
//           "#00A300",
//           "#00D100",
//           "#00FF00",
//           "#AAB8C2",
//           "#cddc39",
//         ],
//         hoverBackgroundColor: [
//           "green",
//           "#76c7c0",
//           "#4caf50",
//           "#8bc34a",
//           // "#cddc39",
//           "#AAB8C2",
//         ],
//         borderWidth: 0,
//       },
//     ],
//   };

//   const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     cutout: "66%",
//     plugins: {
//       tooltip: { enabled: false },
//       legend: { display: false },
//       afterDraw: (chart) => {
//         const { ctx, width, height } = chart;
//         ctx.restore();
//         const fontSize = (height / 114).toFixed(2);
//         ctx.font = `${fontSize}em sans-serif`;
//         ctx.textBaseline = "middle";
//         const text = "54";
//         const subText = "Shareholders";
//         const textX = Math.round((width - ctx.measureText(text).width) / 2);
//         const textY = height / 2 - 10;
//         const subTextX = Math.round(
//           (width - ctx.measureText(subText).width) / 2
//         );
//         const subTextY = height / 2 + 10;
//         ctx.fillText(text, textX, textY);
//         ctx.fillText(subText, subTextX, subTextY);
//         ctx.save();
//       },
//     },
//   };

//   const segments = [
//     { label: "Founders", value: shares?.founderShares || 0, color: "#007500" },
//     // { label: "Founders", value: shares?.founderShares || 0, color: "#76c7c0" },
//     {
//       label: "Investors",
//       value: shares?.investorShares || 0,
//       color: "#00A300",
//     },
//     // { label: "Board", value: shares?.boardsShares || 0, color: "#8bc34a" },
//     { label: "Advisors", value: shares?.mentorsShares || 0, color: "#00D100" },
//     { label: "Others", value: shares?.othersShares || 0, color: "#00FF00" },
//     {
//       label: "Not Allocated",
//       value: shares?.creatorShare || 0,
//       color: "#AAB8C2",
//     },
//   ];

//   return (
//     <div
//       className="p-3 d-flex flex-lg-row border flex-column w-100 justify-content-between align-items-center rounded-xd"
//       style={styles.card}
//     >
//       <div>
//         <div style={styles.header}>
//           {/* <h2 style={styles.valuation}>Shares</h2> */}
//           {/* <p style={styles.shares}>135,767 shares</p> */}
//         </div>
//         <div style={styles.chartContainer}>
//           <Doughnut data={datas} options={options} />
//         </div>
//       </div>
//       <div style={styles.legend}>
//         {segments.map((segment, index) => (
//           <div key={index} style={styles.legendItem} className="my-3">
//             <span>{segment.label}</span>
//             <span className="float-end" style={styles.legendPercentage}>
//               {segment.value}%
//             </span>
//             <div className="" style={styles.progressBarContainer}>
//               <div
//                 style={{
//                   ...styles.progressBar,
//                   width: `${segment.value}%`,
//                   backgroundColor: segment.color,
//                 }}
//               ></div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// const styles = {
//   card: {
//     // padding: "20px",
//     // borderRadius: "10px",
//     backgroundColor: "#fff",
//     textAlign: "center",
//     fontFamily: "Arial, sans-serif",
//   },
//   header: {
//     marginBottom: "20px",
//   },
//   valuation: {
//     margin: 0,
//     fontSize: "24px",
//     fontWeight: "bold",
//   },
//   shares: {
//     margin: 0,
//     fontSize: "14px",
//     color: "#666",
//   },
//   chartContainer: {
//     position: "relative",
//     width: "240px",
//     height: "240px",
//     margin: "0 auto",
//     marginBottom: "10px",
//   },
//   legend: {
//     textAlign: "left",
//   },
//   legendItem: {
//     marginBottom: "10px",
//   },
//   legendPercentage: {
//     fontWeight: "bold",
//   },
//   progressBarContainer: {
//     backgroundColor: "#e0e0e0",
//     borderRadius: "5px",
//     overflow: "hidden",
//     height: "6px",
//     marginTop: "5px",
//     width: "300px",
//   },
//   progressBar: {
//     height: "6px",
//     borderRadius: "5px 0 0 5px",
//   },
// };


// ---------------------------------------------------
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { AssetsDetails } from "../../services/srvc-assets-realm";
import { TokensListUser } from "../../services/srvc-tokens-realm";

export default function SharesValuationCardModule() {
  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [assx, setAssx] = useState(null);
  const [creatorShare, setCreatorShare] = useState();
  const [tokenList, setTokenList] = useState([]);
  const [shares, setShares] = useState({});

  useEffect(() => {
    if (asset) {
      setLoading(true);
      const fetchData = async () => {
        const res = await AssetsDetails({
          data: { user: asset.item },
          srvc: "",
        });

        if (res.stat) {
          setAssx(res.data);
          const tokenRes = await TokensListUser({
            data: { asset: res.data.item },
            srvc: "",
          });

          setTokenList(tokenRes.data.list);

          // Set creator share based on the asset
          const creatorShare = tokenRes.data.list.filter(
            (person) => person.user.item === asset.item
          );
          setCreatorShare(creatorShare);
        } else {
          setData([]);
        }

        setLoading(false);
      };
      fetchData();
    }
  }, [asset]);

  useEffect(() => {
    if (assx && tokenList.length > 0) {
      const calculateShares = () => {
        const otherslist = tokenList.filter(
          (item) => item.user.type === "Others"
        );
        const sumOfUnitsForOthers = otherslist.reduce(
          (sum, item) => sum + item.balance.number,
          0
        );

        const founderslist = tokenList.filter(
          (item) => item.user.type === "Founders"
        );
        const sumOfUnitsForFounder = founderslist.reduce(
          (sum, item) => sum + parseInt(item.balance.number),
          0
        );

        const Investorslist = tokenList.filter(
          (item) => item.user.type === "Investors"
        );
        const sumOfUnitsForInvestors = Investorslist.reduce(
          (sum, item) => sum + parseInt(item.balance.number),
          0
        );

        const Mentorslist = tokenList.filter(
          (item) => item.user.type === "Mentors"
        );
        const sumOfUnitsForMentors = Mentorslist.reduce(
          (sum, item) => sum + parseInt(item.balance.number),
          0
        );

        const Creatorlist = tokenList.filter(
          (item) => item.user.type === "Creator"
        );
        const sumOfUnitsForCreator = Creatorlist.reduce(
          (sum, item) => sum + parseInt(item.balance.number),
          0
        );

        const totalMinted = parseFloat(assx.units.mint);

        const founderShares = (sumOfUnitsForFounder / totalMinted) * 100;
        const mentorsShares = (sumOfUnitsForMentors / totalMinted) * 100;
        const InvestorsShares = (sumOfUnitsForInvestors / totalMinted) * 100;
        const creatorSharePercentage =
          (sumOfUnitsForCreator / totalMinted) * 100;

        // Ensure calculation of others shares takes into account the updated creator share
        const othersShares =
          100 -
          (founderShares +
            mentorsShares +
            InvestorsShares +
            creatorSharePercentage);

        const holding = {
          creatorShare: creatorSharePercentage.toFixed(2),
          founderShares: founderShares.toFixed(2),
          mentorsShares: mentorsShares.toFixed(2),
          investorShares: InvestorsShares.toFixed(2),
          othersShares: othersShares.toFixed(2),
        };

        setShares(holding);
      };

      // Ensure shares are only calculated when assx and tokenList are fully loaded
      calculateShares();
    }
  }, [assx, tokenList]);

  const datas = {
    datasets: [
      {
        data: [
          shares?.founderShares,
          shares?.investorShares,
          shares?.mentorsShares,
          shares?.othersShares,
          shares?.creatorShare,
        ],
        backgroundColor: [
          "#007500",
          "#00A300",
          "#00D100",
          "#00FF00",
          "#AAB8C2",
        ],
        hoverBackgroundColor: [
          "green",
          "#76c7c0",
          "#4caf50",
          "#8bc34a",
          "#AAB8C2",
        ],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "66%",
    plugins: {
      tooltip: { enabled: false },
      legend: { display: false },
      afterDraw: (chart) => {
        const { ctx, width, height } = chart;
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = "middle";
        const text = "54";
        const subText = "Shareholders";
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2 - 10;
        const subTextX = Math.round(
          (width - ctx.measureText(subText).width) / 2
        );
        const subTextY = height / 2 + 10;
        ctx.fillText(text, textX, textY);
        ctx.fillText(subText, subTextX, subTextY);
        ctx.save();
      },
    },
  };

  const segments = [
    { label: "Founders", value: shares?.founderShares || 0, color: "#007500" },
    {
      label: "Investors",
      value: shares?.investorShares || 0,
      color: "#00A300",
    },
    { label: "Advisors", value: shares?.mentorsShares || 0, color: "#00D100" },
    { label: "Others", value: shares?.othersShares || 0, color: "#00FF00" },
    {
      label: "Not Allocated",
      value: shares?.creatorShare || 0,
      color: "#AAB8C2",
    },
  ];

  return (
    <div
      className="p-3 d-flex flex-lg-row border flex-column w-100 justify-content-between align-items-center rounded-xd"
      style={styles.card}
    >
      <div>
        <div style={styles.header}></div>
        <div style={styles.chartContainer}>
          <Doughnut data={datas} options={options} />
        </div>
      </div>
      <div style={styles.legend}>
        {segments.map((segment, index) => (
          <div key={index} style={styles.legendItem} className="my-3">
            <span>{segment.label}</span>
            <span className="float-end" style={styles.legendPercentage}>
              {segment.value}%
            </span>
            <div className="" style={styles.progressBarContainer}>
              <div
                style={{
                  ...styles.progressBar,
                  width: `${segment.value}%`,
                  backgroundColor: segment.color,
                }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

const styles = {
  card: {
    backgroundColor: "#fff",
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    marginBottom: "20px",
  },
  chartContainer: {
    position: "relative",
    width: "240px",
    height: "240px",
    margin: "0 auto",
    marginBottom: "10px",
  },
  legend: {
    textAlign: "left",
  },
  legendItem: {
    marginBottom: "10px",
  },
  legendPercentage: {
    fontWeight: "bold",
  },
  progressBarContainer: {
    backgroundColor: "#e0e0e0",
    borderRadius: "5px",
    overflow: "hidden",
    height: "6px",
    marginTop: "5px",
    width: "300px",
  },
  progressBar: {
    height: "6px",
    borderRadius: "5px 0 0 5px",
  },
};

