// documents
import { Link, useNavigate, useParams } from 'react-router-dom';

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { useState } from 'react';

import '../../data.css/Modal.css'
import FormNeeded from '../webx/form-needed';
import { ContractSendForEsignature } from '../../services/srvc-contract-realm';

const list = require('../../data.static/list-smrt-actions.json').data.filter(item=>item.actv)

export default function ContractActions(props) {
  
  const asset = GetUserForm() === 'user' ? GetLocalUser() : GetLocalBusiness();
  
  const {id} = useParams();
  const navigate = useNavigate();


  const [isOpen, setIsOpen] = useState(false)
  const [submit, setSubmit] = useState()
  const [text,setText] = useState('')
  const [esignmode, setEsignMode] = useState('')
  const [twofAuth, setTwoFAuth] = useState(false)
  
  const data = props.data.docx;
 // console.log(data)
  const lstx = list.filter(item => item.user.includes(asset.role))
  // var idlist = props.data.idlist

  //  console.log(data)

  var admin = data.user.filter(item=>{
        return item.role=="admin"
  }) 

   var failed = {data:{}, memo:'internal server error', stat:false, trxn:'', srvc:''}
    var success = {data:{}, memo:'Transaction Processed Successfully', stat:true, trxn:'', srvc:''}
    var res = success

    if(res.stat)
    {
        setTimeout(()=>{
            setText(res.memo)
        },2000)
    }


   



const close = async()=>{
    setText('')
    setSubmit(false)
   
}

const openModal = () => {
  setIsOpen(true);
};

const closeModal = () => {
  setIsOpen(false);
};


  // console.log(admin)

  const handleEsin = async() => {
    if (props.data.mmbr ===0) {
      alert('Please add Signing Parties');
    }
    if (props.data.file === 0) {
      alert('Please add Files');
      // history.push(`/${asset.form.substring(0,2)}/documents/esin/location/${id}`);
    }
    if (props.data.mmbr >0 && props.data.file >0) {
      // navigate(`/${asset.role}/contract/esin/location/${id}`);
      setIsOpen(true)
    }
  }
 
  const handleMembers = async() => {
    navigate(`/${asset.role}/contract/members/${id}`)
  }

  const handleEstm = async() => {
    navigate(`/${asset.role}/contract/add/stamp/${id}`)
  }
  
  const handleStampList= async() => {
    navigate(`/${asset.role}/contract/add/stamp/${id}`)
  }

  const handleFiles = async() => {
    navigate(`/${asset.role}/contract/add/file/${id}`)
  }

  const handleTime = async() => {
    navigate(`/${asset.role}/smart/v/track/${id}`)
  }

  const handleRoom = async() => {
    // console.log ('discuss')
    navigate(`/${asset.role}/rooms/${id}`)
  }

  const handleFeatures = async() => {
    // history.push(`/${asset.form.substring(0,2)}/documents/edit/feat/${id}`)
  }

  const handleArchive = async() => {
    // console.log ('archive')
  }
  
  const handleButton = async(code) => {

    switch(code) {
      case 'esin':
        handleEsin();
        break;
      case 'estm':
        handleEstm();
        break;
      case 'file':
        handleFiles();
        break;
      case 'members':
        handleMembers();
        break;
      case 'estmlist':
        handleStampList();
        break;
      case 'file':
        // handleFiles();
        break;        
      case 'time':
        handleTime();
        break;        
      case 'room':
        handleRoom();
        break;
      case 'feat':
        handleFeatures();
        break;        
      case 'arch':
        handleArchive();
        break;
      default:
        // code block
    }

  }


  const SendForEsign = async()=>{
    if(esignmode=="")
    {
      window.alert("Please choose Esign mode")
    }
    else
    {
      var datx = { smart:id, mode: esignmode, auth: twofAuth}
      // console.log(datx)
      var res= await ContractSendForEsignature({data: datx, srvc:''})
      // console.log(res)
    }

  }

  return (
    <>
      
   <div className="rounded-wd back-color-wite">
    {lstx && lstx.length>0 && lstx.map ((item, i) => (

      <div onClick={()=> handleButton(item.code)}  className="p-1 border-bottom border-light">

        <div className={`d-flex cursor p-2 hilite rounded-wd ${ i < data.length-1 ? '': ''}`} key={i}
          // onClick={()=>{handleChange('item',item.name); handleCallback(item.name)}}
          // onClick={()=>{navigate(`/${usxx}/vault/${item.link}`)}}
        >
        
          <div className="">
            <span className="text-small">
              <i className={`m-0 p-0 small ${item.icon}`} style={{fontSize:'18px'}}></i>
            </span>
          </div>
          
          <div className="ms-2">
            <p className={`m-0 p-0 text-small text-$'color-next `}>
              {item.name}
            </p>
          </div>

          <div className="ms-auto">
            <i className={`m-0 p-0 text-color-tint bi-chevron-right`}></i>
          </div>
        
        </div>
        <div className="border-bottom border-light d-none"></div>
      </div>
    ))}
    
    </div>

    {/* Modal foe send esign */}
    {isOpen && (
        <div className='container '>
            <div className='row '>
                <div className='col-6 '>
                    <div className="modal-overlay  " onClick={closeModal}>
                    <div className="modal-content "  onClick={(e) => e.stopPropagation()}>
                        <span className="close-button" onClick={closeModal}>&times;</span>
                        {/* modal header */}
                        <div className='p-3 m-0'>
                            <div className='header fw-bold m-0 text-center'>Send Agreement For Signature</div>
                        </div>
                        {/* modal boarder */}
                        <div className='scrollbar' style={{overflowY:'scroll', height:'400px'}}>
                                       
                            <div className="border-bottom mt-2 mb-2"></div>
                            <div className='p-3'>
                              <div className=''>
                                <p>1. Choose Esign mode <FormNeeded /></p>
                              </div>
                              <div className='d-flex'>
                                <div className=''  onClick={()=> setEsignMode("adhr")}>
                                <div className={esignmode =="adhr"? 'cursor mx-3 text-center ':' cursor mx-3 text-center'} style={{width:'105px', height:'75px'}}>
                                  <img className={esignmode =="adhr"? "border":""} src='https://presentations.gov.in/wp-content/uploads/2020/06/Aadhaar_Preview.png?x31571' style={{width:'85px', height:'70px'}}   />
                                </div>
                                <p className='text-small text-center'>Aadhaar OTP</p>
                                </div>

                                <div className='' onClick={()=> setEsignMode("emid")}>
                                <div className={esignmode =="emid"? 'cursor mx-3 text-center ':' cursor mx-3 text-center'} style={{width:'105px', height:'75px'}}>
                                  <img className={esignmode =="emid"? "border":""} src='https://media.istockphoto.com/id/826567080/vector/e-mail-icon-simple-vector-illustration-red-color.jpg?s=612x612&w=0&k=20&c=ysxmzarWz_6a2oyi1ue9p6OUBXAw8W1LQPsyorc_5hY=' style={{width:'85px', height:'70px'}} />
                                </div>
                                <p className='text-small text-center'>Email OTP</p>
                                </div>

                                {/* onClick={()=> setEsignMode("mmid")} */}
                                <div className='' > 
                                <div className={esignmode =="mmid"? ' mx-3 text-center ':'  mx-3 text-center'} style={{width:'105px', height:'75px'}}>
                                  <img className={esignmode =="mmid"? "image-center border":""} src='https://static.vecteezy.com/system/resources/previews/023/128/305/non_2x/otp-one-time-password-login-code-verification-concept-icon-in-line-style-design-isolated-on-white-background-editable-stroke-vector.jpg' style={{width:'85px', height:'65px'}} />
                                </div>
                                <p className='text-small text-center'>Mobile OTP</p>
                                </div>
                              </div>
                             </div>

                             <div className='p-3'>
                              <div className='d-flex'>
                                <p className='m-0'>2. 2-Factor Authentication</p>
                                <p className='m-0'><input onChange={()=>setTwoFAuth(!twofAuth)} className='mx-3' type='checkbox' /></p>
                              </div>
                              <p className='mx-3 text-small'>(This will ask user to authenticate before signing the document)</p>
                             </div>
                                                        
                        </div>
                        <hr/>


                        {/* Modal Footer */}
                        <div className='d-flex p-2' >
                            <div className='me-auto'>
                                <button onClick={()=>closeModal()} className='btn btn-light  rounded-xx w-100'>Back</button>
                            </div>
                            <div className='text-end' >
                                <button onClick={()=>{SendForEsign()}} className='btn btn-outline-success rounded-xx w-100'>Send For Esign</button>
                            </div>
                        </div>

                        
                    </div>
                    </div>
                </div>
            </div>
        </div>

      )}

    </>
    )
  }
