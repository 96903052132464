// documents
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from 'react-router-dom';

import WebbLoader from "../webx/webb-loader-sm";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import HeaderSectionDark from "../webx/head-section-dark";

import DocumentOverview from "../webx/contract-overview";

import DocumentMembers from "../webx/docx-members";
import DocumentFiles from "../webx/docx-files";

import { GetUserForm,  } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import { ContractDocumentDetails } from "../../services/srvc-contract-realm";
import { ContractDocumentFileList } from "../../services/srvc-media-docs-realm";

// import { GetDocumentInfo, GetDocumentFiles } from "../../services/srvc-smart-realm";
// import {DocumentCancel} from "../../services/srvc-smart-realm";
// import { SendEsinReminderAlert } from "../../services/srvc-alerts-realm";


export default function DocumentsViewModule() {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const {id } = useParams();
  
  const [loading, setLoading] = useState(true);

  const [info, setInfo] = useState({});
  const [mmbr, setMembers] = useState([]);
  const [file, setFiles] = useState([]);
  const [active,setActive] =useState(true)
  const [admin,setAdmin] = useState()

  const [reminder, setReminder] = useState(false)


  useEffect( () => {
    if (asset){
      const fetchData = async() => {

        // info + files
        var res = await ContractDocumentDetails({data:{item: id}, srvc:''})
        //  console.log(res)
         if(res.stat)
         {
          var mmbx = res?.data?.members
          setMembers(mmbx)
  
          // var admin = res.user.filter(item=>{
        //   return item.role=="admin"
        // }) 
        // // console.log(admin)
        // setAdmin(admin)
        setInfo(res.data);

        // files
        const files =await ContractDocumentFileList({data:{document: id}, srvc:''})
        // console.log(files)
        if(files.stat)  setFiles(files.data.list);
      
         }


        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.item, active]);

  const DoCancelDocument = async() => {
    // var res=(await DocumentCancel(JSON.stringify({data:{document:id}}))).data
    // console.log(res)
    setActive(false)
  }




const SendReminderAlert=async()=>{
  setReminder(true)
  const datx = {
    data: { document: id },
  }
  
  // var res = (await SendEsinReminderAlert(JSON.stringify(datx))).data
  // // console.log(res)
  // if(res!= false)
  // {
  //   setReminder(false)
  //   window.alert(`E-signature reminder has been sent to ${res.name} on email ${res.mail}`)
  // }
  
}

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    {/* <WebbDividerSmall /> */}
    {/* <DocumentOverview data={info}/> */}

    {/* action */}
    {/* <WebbDividerMedium /> */}
    {/* <div className="d-flex d-none" >

    <div className={info.status=="2" && admin[0].usid== asset.user ? 'w-25': 'd-none'}>
        <button onClick={()=>SendReminderAlert()} className="btn btn-success back-color-wite text-color-tone border-none"><small>Reminder</small></button>
      </div>

      <div className={info.status=="6" && admin[0].usid== asset.user  || info.status=="11" && admin[0].usid== asset.user ? 'd-none': 'w-25'}>
        <button onClick={()=>DoCancelDocument()} className="btn btn-success back-color-wite text-color-tone border-none"><small>Cancel</small></button>
        
      </div>

      <div className={` ${reminder?'':'d-none'} spinner-border text-primary" role="status"`}>
        <span className="visually-hidden">Loading...</span>
      </div>


    </div> */}

    {/* members */}
    {/* <WebbDividerMedium /> */}
    <HeaderSectionDark data={{name: 'Parties'}}/>
    <DocumentMembers docx={info} data={mmbr}/>

    {/* files */}
    <WebbDividerMedium />
    <HeaderSectionDark data={{name: 'Files'}}/>
    <DocumentFiles status = {info.files.length} data={file}/>
    

    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />

  </>
  )
}