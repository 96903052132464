// content
import { useEffect, useState } from "react";

import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import webbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";

import { AssetsDetails } from "../../services/srvc-assets-realm";
import { TokensListUser } from "../../services/srvc-tokens-realm";

export default function SharesChartLineModule() {
  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [assx, setAssx] = useState("");

  const [creatorShare, setCreatorShare] = useState();
  const [sale, setSale] = useState(0);
  const [allocated, setAllocated] = useState(0);

  useEffect(() => {
    if (asset) {
      setLoading(true);
      const fetchData = async () => {
        setLoading(true);
        var res = await AssetsDetails({ data: { user: asset.item }, srvc: "" });
        console.log(res);

        if (res.stat) {
          var result = await TokensListUser({
            data: { asset: res.data.item },
            srvc: "",
          });

          const total = result.data.list.reduce(
            (sum, item) => sum + parseInt(item.balance.number),
            0
          );

          const nonAllocatedValue = result.data.list
            .filter((person) => person.user.item == asset.item)
            .reduce((sum, item) => sum + parseInt(item.balance.number), 0);
          // setTokenList(res.data.list);
          setData(res.data);

          const sold = total - nonAllocatedValue;
         setAllocated(sold)

          var salePercent = parseFloat(
            ((total - nonAllocatedValue) / parseFloat(res.data.units.mint)) *
              100
          ).toFixed(2);
          setSale(salePercent.toString() + "%");
        }

        setLoading(false);
      };
      fetchData();
    } else {
    }
  }, [asset.item]);

  if (loading) {
    return <>Please Wait...</>;
  }

  return (
    <>
      <div className="">
        <p className="text-bold">Allocation Overview</p>
      </div>

      <div className="back-color-wite border rounded-xd p-3">
        <div className="d-flex justify-content-between mb-1">
          <div className="">
            Allocated
            {/* parseFloat(creatorShare[0]?.balance?.number || "0") */}
          </div>
          <div className="text-end">
            <p className="m-0">
              <span className="">
              <p className="text-lead m-0">{NumberFormat(allocated || 0, "w")}</p>
              </span>
              <span className=""></span>
            </p>
          </div>
        </div>

        <div className="progress rounded-md mt-2 mb-2" style={{ height: "20px" }}>
          <div
            className="progress-bar
"
            role="progressbar"
            style={{ width: sale, backgroundColor: "green" }}
            aria-valuenow={sale}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>

        <div className="d-flex justify-content-between mt-1">
          <div className="">
            <p className="m-0">
              Total
            </p>
          </div>
          <div className="text-end">
            <p className="text-normal m-0">
              {NumberFormat(data?.units?.mint || 0, "w")}
            </p>
          </div>
        </div>
        <webbDividerMedium />
      </div>
    </>
  );
}
