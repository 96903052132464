// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { NumberFormat, CardNumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbIcon from "../webx/webb-icon";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import FormNeeded from "../webx/form-needed";
import { TeamAccounCreate, TeamMemberAdd } from "../../services/srvc-users-realm";

// import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";

// const media = 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'
const media = 'https://img.freepik.com/free-photo/blockchain-technology-background-gradient-blue_53876-124646.jpg?w=300'

export default function TeamAccountCreateModule () {

  
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  // console.log(asset)
  
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const [balance, setBalance] = useState({number: '000000', ticker: '******'})
  const [name, setName] = useState('')
  const [mail, setMail] = useState('')
  const [mobile, setMobile] = useState('')
  const [memo, setMemo] = useState('')
  const [submit, setSubmit] = useState(false)

  useEffect( () => {

    if (asset){
      const fetchData = async() => {
        setLoader(true);
        
        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  const handleSubmit = async()=>{
    setMemo("Please Wait...")
    setSubmit(true)
    var datx = {
        name:name,
        mail:mail,
        mobile:mobile,
        active:true,
        self:true
    }
    var res = await TeamAccounCreate({data: datx, srvc:''})
    //console.log(res)
    if(res.stat){
        // add user in team
        setMemo('Team Account Created')
        datx = {
            team:res.data.item,
            user:{ name: asset.name, mail:asset.mail, item: asset.item },
            role:"owner"
        }
        var res = await TeamMemberAdd({data:datx, srvc:''})
        //console.log(res)
        navigate(`/${asset.role}/w/teams`)
    }
    else{
        setMemo(res.memo)
    }
    
  }



  return (
  <>
    {/* info */}

    {/* data */}
    <div className="rounded-none border p-3 bg-white">
      
      <div className="">
        <label className="text-small mb-0">Name<FormNeeded/></label>
        <input value={name} onChange={(e)=> setName(e.target.value)} className="form-control mt-1" />
        <WebbDividerSmall />

        <label className="text-small mb-0">Mail<FormNeeded/></label>
        <input type="email" value={mail} onChange={(e)=> setMail(e.target.value)}  className="form-control mt-1" />
        <WebbDividerSmall />

        <label className="text-small mb-0">Mobile<FormNeeded/></label>
        <input value={mobile} onChange={(e)=> setMobile(e.target.value)}  className="form-control mt-1" />
        <WebbDividerSmall />
        <p className="text-small text-primary mt-2">{memo}</p>

        <button onClick={()=>handleSubmit()} disabled={ !name || !mail || !mobile} className="btn btn-outline-primary text-small w-100 mb-3 mt-4">Create</button>

        
      </div>

    </div>

    

  </>

  )
}