// main
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import ContentFormat from "../content/webz/content-format-xv";
import WebbHeader from "../content/webz/webb-header-navs-xv";

import WebbIcon from "../content/webx/webb-icon";
import UserAvatar from "../content/webx/user-avatar";
import WebbDateTime from "../content/webx/webb-datetime";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import HeaderSectionDark from "../content/webx/head-section-dark";

import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-local";
import { GetUserForm } from "../services/srvc-utilities";

import NetworkSelectModule from "../content/network/network-select";
import NetworkActionsUserModule from "../content/network/network-actions-user";
import WebbSpinText from "../content/webx/webb-spintext";
import NetworkMembers from "../content/webx/network-list";
import { ContactList } from "../services/srvc-users-realm";
import NetworkFilterModule from "../content/network/network-filter";
import MenuButtons from "../content/webx/menu-buttons";
import WebbMenuMobile from "../content/webz/webb-menu-xv";
import TeamInfocardHomeModule from "../content/user/team-infocard-home";
import MobileSidebarModule from "../content/sidebar/sidebar-menu";
import FloatButton from "../content/float/float-button";

export default function Network() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const metadata = {
    name: "Network",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  const [sort, setSort] = useState("Profile");

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchdata = async () => {
      var datx = {
        team: asset.item,
        role: "Founders",
      };
      var res = await ContactList({ data: datx, srvc: "" });
      // console.log(res)
      if (res.stat) setData(res.data.list);
      setLoading(false);
    };
    fetchdata();
  }, []);

  const handleSort = async (item) => {
    // console.log('item: ', item)
    setSort(item);
  };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-md" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              {" "}
              <UserAvatar />{" "}
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              {" "}
              <p className="text-normal text-bold m-0">{metadata.name}</p>{" "}
            </>
          ),
          data: (
            <>
              <NetworkSelectModule />
            </>
          ),
          footer: (
            <>
              <WebbDateTime />
            </>
          ),
        }}
        content={{
          header: (
            <>
              <MobileSidebarModule heading="Network" />
            </>
          ),
          data: (
            <>
              <NetworkFilterModule />
              {loading ? (
                <>
                  <WebbSpinText />
                </>
              ) : (
                <>
                  <NetworkMembers data={data} />
                </>
              )}
              <FloatButton action={NetworkActionsUserModule} />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <NetworkActionsUserModule />
            </>
          ),
          footer: <></>,
        }}
      />
    </>
  );
}
