// module
import { useEffect, useState } from "react";
import {  useNavigate, useParams } from 'react-router-dom';


import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm, toBase64 } from '../../services/srvc-utilities'

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";
import { AssetsCreate, AssetsDetails } from "../../services/srvc-assets-realm";
import axios from "axios";
import CertificateCreator from "../webx/certificate-create";


export default function AssetsInitModule() {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [form, setForm] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [file, setFile] = useState('')
  const [assx, setAssx] = useState()

  const [memo, setMemo] = useState('');
  const [data, setData] = useState({    
    name: asset?.name || '',
    memo: `${asset?.name} Tokenized Shares` || '',
    unit: '10000',
    rate: '10'
  })
  const [disable, setDisable] = useState(false)


  useEffect( () => {
    setLoader(true)
    if (asset){
      const fetchData = async() => {
          var res = await AssetsDetails({data:{user:asset.item}, srvc:''})
          // console.log(res)
          if(res.stat){
            var name = res?.data?.meta?.name
            var memo = res?.data?.meta?.memo
            var rate = parseFloat(res?.data?.rate.number)/1000000
            var unit = res?.data?.units?.mint
            setData({name:name, memo: memo , rate:rate, unit:unit })
            setDisable(true)
            setAssx(res.data)
          }
        // fetch data

        setLoader(false);
      }
      fetchData()
    } else {}
  },[asset.item]);



  const handleChange = async(key, val) => {
    setData({ ...data, [key]: val });
  }

  const handleSubmit = async () => {
    setLoader(true)
    var base64 = await toBase64(file)
    
    var datax = {
      creator : asset.item,
      name: data.name,
      memo: data.memo,
      dates:{ start:Date.now(), end:Date.now(), valid:Date.now(), issue:Date.now()},
      units: { number:data.unit, ticker:"STX"},
      chain: "416001",
      rate : {number:data.rate, ticker:'INR'},
      media:{image:base64, }
    }
    
    var res = await AssetsCreate({data: datax, srvc:''})
    if(res.stat){ navigate(`/${asset.role}/shares/table`)}


  }


  if (loader){ return ( <div className="text-center"> <WebbLoaderSmall /> </div> ) }

  return (
  <div className="">
    
    {/* info */}
    <p className="mx-3">Please review the following information and submit</p>
    {/* <WebbDividerMedium /> */}

    {/* data */}
    <div className="mx-3">
      <div className="form-group mb-3 ">
        <label className="form-label small">Name <FormNeeded/></label>
        <input type="text" className="form-control rounded-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}}
          value={data.name}
          maxLength={31}
          disabled={disable}
          onChange={({ target }) => {handleChange("name", target.value);}}
          placeholder="0123">
        </input>
      </div>

      <div className="form-group mb-3 ">
        <label className="form-label small">Short Description <FormNeeded/></label>
        <input type="text" className="form-control rounded-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}}
          value={data.memo}
          disabled={disable}
          onChange={({ target }) => {handleChange("memo", target.value);}}
          placeholder="0123">
        </input>
      </div>

      <div className="form-group mb-3 ">
        <label className="form-label small">Number of Units <FormNeeded/></label>
        <input type="text" className="form-control rounded-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}}
          value={data.unit}
          disabled={disable}
          onChange={({ target }) => {handleChange("unit", target.value);}}
          placeholder="0123">
        </input>
      </div>

      <div className="form-group mb-3 ">
        <label className="form-label small">Offer Rate <FormNeeded/></label>
        <input type="text" className="form-control rounded-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}}
          value={data.rate}
          disabled={disable}
          onChange={({ target }) => {handleChange("rate", target.value);}}
          placeholder="0123">
        </input>
      </div> 
      <div  className={disable? 'd-none':"form-group mb-3 "}>
        <label className="form-label small">Choose Logo<FormNeeded/></label>
        <input type="file" className="form-control rounded-none"
          style={{fontSize:'0.9rem', height:'2.7rem'}}
          disabled={disable}
          onChange={(e) => {setFile(e.target.files[0]);}}
          >
        </input>
      </div> 

      <div className={disable? '':"d-none"}>
        <img className="img-fluid" src ={assx?.media?.link}  />
      </div>  

      

    </div>


    
    <div className="mx-3">
      <p className="m-0">{memo}</p>
    </div>

    
    <div className={submit ? 'd-none' : 'mx-3'}>
      <WebbDividerMedium />
      <div className="d-flex justify-content-between">

        <button className={`btn btn-light border back-color-wite rounded-xx text-small`}
          type="button"
          disabled={loader || submit}
          onClick={()=> { navigate(-1)}}
        >{loader ? 'Please Wait...' : 'Cancel'}</button>

        <button className={`btn btn-success border-none rounded-xx text-small`}
          type="button"
          disabled={ disable || loader || submit}
          onClick={()=> { handleSubmit()}}
        >{loader ? 'Please Wait...' : 'Submit'}</button>

      </div>


    </div>
  
    <WebbDividerMedium />
    <WebbDividerMedium />
  </div>
  
  )
}