// network
import { useEffect, useState } from "react";
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import list from '../../data.static/data-assets-list-user.json'


export default function AssetsListStocksUserModule() {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const [shares, setShares] = useState({number: 0})

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        setData(list.data)
        // setData(list.filter(item => item.user.includes(asset.form)))

        // const sharex = list.data.reduce((a, x) => a + parseInt(x.sale.number), 0)
        // setShares(sharex)

        setData(list.data)

        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.item]);
  


  if (loading){ return ( <>Please Wait...</> ) }

  return (
    
    <>
      <div className="rounded-wd back-color-wite border">

      {data && data.length > 0 && data.map((item, i) => (
  
        <div className="p-1 border-bottom">
  
          <div className={`d-flex cursor p-2 rounded-wd ${ i < data.length-1 ? '': ''}`} key={i}>
          
            <div className="">
              <span className="" style={{}}>
                <Jazzicon diameter={30} seed={jsNumberForAddress(item.meta.item)} />    
              </span>
            </div>
            
            <div className="ms-2" style={{width: '', lineHeight: '1rem'}}>
              <p className={`m-0 p-0 text-bold`}>{item.meta.name}</p>
              <p className={`m-0 p-0 text-small`}>{item?.meta?.number || '******'}</p>
            </div>

            <div className="ms-auto" style={{width: ''}}>
              <p className={`m-0 p-0 text-bold text-end`}>
                {item?.units?.number || '0' }
              </p>              
            </div>

            

          
          </div>
          <div className="border-bottom border-light d-none"></div>
        </div>
      ))}

      </div>
    </>
    )
  }