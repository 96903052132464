// main
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

import ContentFormat from '../content/webz/content-format-xv'
import WebbHeader from '../content/webz/webb-header-navs-xv'

import WebbIcon from '../content/webx/webb-icon'
import UserAvatar from '../content/webx/user-avatar'
import WebbDateTime from '../content/webx/webb-datetime'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import HeaderSectionDark from '../content/webx/head-section-dark'

import { GetLocalBusiness, GetLocalUser } from '../services/srvc-auth-local'
import { GetUserForm } from '../services/srvc-utilities'

import SharesSelectModule from '../content/shares/shares-select'
import SharesUserActionsModule from '../content/shares/shares-actions-user'

import AssetsListCommonUserModule from '../content/shares/assets-list-cx-user'
import AssetsListPreferUserModule from '../content/shares/assets-list-cx-user'
import AssetsListStockUserModule from '../content/shares/assets-list-es-user'


export default function AssetsListUser () {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  

  const metadata = {
    name: 'Shares',
    banner: { link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'}
  }

  
  return (
    <>
      <Helmet>
        <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: 'xtra', show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon data={{ color: 'text-color-main', size: 'text-icon-md' }} />
              </Link>
            </>
          ),
          data: ( <> <WebbHeader /> </> ),
          footer: ( <> <UserAvatar /> </> )
        }}
        sidebar={{
          header: ( <> <p className='text-normal text-bold m-0'>{metadata.name}</p> </>),
          data: (
            <>
            <SharesSelectModule />
            

            </>
          ),
          footer: <><WebbDateTime /></>
        }}
        content={{
          header: ( <> <p className='m-0'>Portfolio</p> </> ),
          data: (
            <>
              <HeaderSectionDark data={{name: 'Common Shares'}}/>
              <AssetsListCommonUserModule />

              <WebbDividerMedium />
              <HeaderSectionDark data={{name: 'Preferred Shares'}}/>
              <AssetsListPreferUserModule />

              <WebbDividerMedium />
              <HeaderSectionDark data={{name: 'Stock Options'}}/>
              <AssetsListStockUserModule />

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          )
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: ( <> 
            <SharesUserActionsModule />
          </> ),
          footer: <></>
        }}
      />
    </>
  )
}
