// // module
// import { useEffect, useState } from "react";
// import {  useNavigate, useParams } from 'react-router-dom';


// import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
// import { GetUserForm } from '../../services/srvc-utilities'

// import WebbLoaderSmall from "../webx/webb-loader-sm";
// import WebbDividerMedium from "../webx/webb-divider-md";
// import WebbDividerSmall from "../webx/webb-divider-sm";
// import FormNeeded from "../webx/form-needed";
// import { AssetsDetails, UnitsCreate } from "../../services/srvc-assets-realm";



// export default function AssetsNewModule() {

//   const usxx = GetUserForm()
//   const usrx = GetLocalUser()
//   const temx = GetLocalBusiness()
//   const asset = usxx === 'user' ? usrx : temx

//   const navigate = useNavigate();

//   const [loader, setLoader] = useState(true);
//   const [form, setForm] = useState(false);
//   const [submit, setSubmit] = useState(false);
//   const [assx, setAssx] = useState('')

//   const [memo, setMemo] = useState('');
//   const [data, setData] = useState({
//     number: '',
//     rate: ''
//   })


//   useEffect( () => {
//     if (asset){
//       const fetchData = async() => {
//         var res = await AssetsDetails({data:{user:asset.item}, srvc:''})
//         if(res.stat){ setAssx(res.data.item)}
//         setLoader(false);
//       }
//       fetchData()
//     } else {}
//   },[asset.usid]);



//   const handleChange = async(key, val) => {
//     setData({ ...data, [key]: val });
//   }

//   const handleSubmit = async () => {
//     setSubmit(true)
//     var datax = {
//       asset:assx,
//       dates:{ start:Date.now(), end:Date.now(), valid:Date.now(), issue:Date.now(), lock: Date.now()},
//       size: data.number,
//       rate : {number:data.rate, ticker:'INR'},
//     }
    
//     var res = await UnitsCreate({data: datax, srvc:''})
//     // console.log(res)
//     if(res.stat){
//       navigate(`/team/shares/table`)
//     }
  
//   }


//   if (loader){ return ( <> <WebbLoaderSmall /> </> ) }

//   return (
//   <div className="">
    
//     {/* info */}
//     <p className="m-0">Please enter Number of Units and Rate</p>
//     <WebbDividerMedium />

//     {/* data */}
//     <div className="">
//       <div className="form-group mb-3 ">
//         <label className="form-label small">Number of Units <FormNeeded/></label>
//         <input type="text" className="form-control rounded-none"
//           style={{fontSize:'0.9rem', height:'2.7rem'}}
//           value={data.name}
//           onChange={({ target }) => {handleChange("number", target.value);}}
//           placeholder="0123">
//         </input>
//       </div>

//       <div className="form-group mb-3 ">
//         <label className="form-label small">Unit Rate <FormNeeded/></label>
//         <input type="text" className="form-control rounded-none"
//           style={{fontSize:'0.9rem', height:'2.7rem'}}
//           value={data.name}
//           onChange={({ target }) => {handleChange("rate", target.value);}}
//           placeholder="0123">
//         </input>
//       </div>
//     </div>


//     <WebbDividerMedium />
//     <div className="mx-3">
//       <p className="m-0">{memo}</p>
//     </div>

//     <WebbDividerMedium />
//     <div className={submit ? 'd-none' : 'mx-3'}>
//       <WebbDividerMedium />
//       <div className="d-flex justify-content-between">

//         <button className={`btn btn-light border back-color-wite rounded-xx text-small`}
//           type="button"
//           disabled={loader || submit}
//           onClick={()=> { navigate('/auth/next')}}
//         >{loader ? 'Please Wait...' : 'Cancel'}</button>

//         <button className={`btn btn-success border-none rounded-xx text-small`}
//           type="button"
//           disabled={ loader || submit}
//           onClick={()=> { handleSubmit()}}
//         >{loader ? 'Please Wait...' : 'Continue'}</button>

//       </div>


//     </div>
  
//     <WebbDividerMedium />
//     <WebbDividerMedium />
//   </div>
//   )
// }

// -------------------------------------------------------------

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

import { GetLocalBusiness, GetLocalUser } from "../../services/srvc-auth-local";
import { GetUserForm } from "../../services/srvc-utilities";

import WebbLoaderSmall from "../webx/webb-loader-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";
import { AssetsDetails, UnitsCreate } from "../../services/srvc-assets-realm";

export default function AssetsNewModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [form, setForm] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [assx, setAssx] = useState("");
  const [memo, setMemo] = useState("");
  const [data, setData] = useState({ number: "", rate: "" });

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        var res = await AssetsDetails({ data: { user: asset.item }, srvc: "" });
        if (res.stat) {
          setAssx(res.data.item);
        }
        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, [asset.usid]);

  const handleChange = (key, val) => {
    setData({ ...data, [key]: val });
  };

  const handleSubmit = async () => {
    setSubmit(true);
    setShowModal(true); // Show the modal

    var datax = {
      asset: assx,
      dates: {
        start: Date.now(),
        end: Date.now(),
        valid: Date.now(),
        issue: Date.now(),
        lock: Date.now(),
      },
      size: data.number,
      rate: { number: data.rate, ticker: "INR" },
    };

    var res = await UnitsCreate({ data: datax, srvc: "" });

    // Simulate a delay of 2 minutes (120000 ms) for unit creation
    setTimeout(() => {
      if (res.stat) {
        setShowModal(false);
        navigate(`/team/shares/table`);
      }
    }, 30000); // 2 minutes in milliseconds
  };

  if (loader) {
    return (
      <>
        <WebbLoaderSmall />
      </>
    );
  }

  return (
    <div className="">
      {/* info */}
      <p className="m-0">Please enter Number of Units and Rate</p>
      <WebbDividerMedium />

      {/* data */}
      <div className="">
        <div className="form-group mb-3">
          <label className="form-label small">
            Number of Units <FormNeeded />
          </label>
          <input
            type="text"
            className="form-control rounded-none"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={data.number}
            onChange={({ target }) => {
              handleChange("number", target.value);
            }}
            placeholder="0123"
          ></input>
        </div>

        <div className="form-group mb-3">
          <label className="form-label small">
            Unit Rate <FormNeeded />
          </label>
          <input
            type="text"
            className="form-control rounded-none"
            style={{ fontSize: "0.9rem", height: "2.7rem" }}
            value={data.rate}
            onChange={({ target }) => {
              handleChange("rate", target.value);
            }}
            placeholder="0123"
          ></input>
        </div>
      </div>

      <WebbDividerMedium />
      <div className="mx-3">
        <p className="m-0">{memo}</p>
      </div>

      <WebbDividerMedium />
      <div className={submit ? "d-none" : "mx-3"}>
        <WebbDividerMedium />
        <div className="d-flex justify-content-between">
          <button
            className={`btn btn-light border back-color-wite rounded-xx text-small`}
            type="button"
            disabled={loader || submit}
            onClick={() => {
              navigate("/auth/next");
            }}
          >
            {loader ? "Please Wait..." : "Cancel"}
          </button>

          <button
            className={`btn btn-success border-none rounded-xx text-small`}
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal1"
            disabled={loader || submit}
            onClick={handleSubmit}
          >
            {loader ? "Please Wait..." : "Continue"}
          </button>
        </div>
      </div>

      <WebbDividerMedium />
      <WebbDividerMedium />

      <div
        className="modal fade rounded-xd"
        id="exampleModal1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
      >
        <div className="modal-dialog rounded-xd mt-5">
          <div className="modal-content w-100" >
            <div className="modal-header border-none align-middle">
              <p className="text-lead m-0" id="exampleModalLabel">
                Processing....
              </p>
              <button
                type="button"
                className="btn-close "
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <div className="modal-body">
              <p>
                Unit creation is being processed. Please wait for 30 seconds.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
