// main
// import "./assets-unit.css";
import { useState,useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import ContentFormat from "../content/webz/content-format-xv";
import WebbHeader from "../content/webz/webb-header-navs-xv";

import WebbIcon from "../content/webx/webb-icon";
import UserAvatar from "../content/webx/user-avatar";
import WebbDateTime from "../content/webx/webb-datetime";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import HeaderSectionDark from "../content/webx/head-section-dark";

import { GetLocalBusiness, GetLocalUser } from "../services/srvc-auth-local";
import { GetUserForm } from "../services/srvc-utilities";

import SharesSelectModule from "../content/shares/shares-select";
import SharesUserActionsModule from "../content/shares/shares-actions-user";


import SharesSelectMobileModule from "../content/shares/share-select-mobile";
import Draggable from "react-draggable";
import MenuButtons from "../content/webx/menu-buttons";
import WebbMenuMobile from "../content/webz/webb-menu-xv";
import TeamInfocardHomeModule from "../content/user/team-infocard-home";
import FloatButton from "../content/float/float-button";

import { AssetsDetails } from "../services/srvc-assets-realm";

import SharesChartLineModule from "../content/shares/shares-chart-line";
import SharesValuationCardModule from "../content/shares/shares-valuation-card";
import SharesTableTeamModule from "../content/shares/shares-table-team";

export default function AssetsTableTeam() {
  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const [loading, setLoading] = useState(true);
  const [status, setStaus] = useState(false);

  useEffect(() => {
    if (asset) {
      setLoading(true);
      const fetchData = async () => {
        setLoading(true);
        var res = await AssetsDetails({
          data: { user: asset.item },
          srvc: "",
        });

        if (res.stat) {
          setStaus(res.stat);
        } else {
          setStaus(false);
        }
        setLoading(false);
      };
      fetchData();
    } else {
    }
  }, [asset.item]);

  const metadata = {
    name: "Shares",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`/${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-md" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              {" "}
              <UserAvatar />{" "}
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              {" "}
              <p className="text-normal text-bold m-0">{metadata.name}</p>{" "}
            </>
          ),
          data: (
            <>
              <SharesSelectModule />
            </>
          ),
          footer: (
            <>
              <WebbDateTime />
            </>
          ),
        }}
        content={{
          header: (
            <>
              <div className="d-flex justify-content-between justify-content-md-start align-items-start">
                <div className="d-flex gap-3">
                  <i
                    data-bs-toggle="offcanvas"
                    href="#offcanvasExample"
                    role="button"
                    aria-controls="offcanvasExample"
                    className="bx bx-menu d-block d-md-none"
                    style={{ fontSize: "25px" }}
                  ></i>
                  <div
                    className="offcanvas offcanvas-start w-75 bg-body-tertiary"
                    tabIndex="-1"
                    id="offcanvasExample"
                    aria-labelledby="offcanvasExampleLabel"
                  >
                    <div className="offcanvas-header">
                      <h6
                        className="offcanvas-title"
                        id="offcanvasExampleLabel"
                      >
                        Contrato
                      </h6>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div
                      className="offcanvas-body d-flex flex-column gap-1"
                      style={{ position: "relative" }}
                    >
                      <TeamInfocardHomeModule />
                      {/* <UserInfocardMobileModule /> */}
                      {/* <UserAccountBalance /> */}
                      <WebbMenuMobile />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "15px",
                          width: "88%",
                        }}
                      >
                        <MenuButtons />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className="m-0">Shares</p>{" "}
                </div>
                <div
                  className="d-block d-md-none"
                  style={{ position: "relative", bottom: "6px" }}
                >
                  <UserAvatar />
                </div>
              </div>
            </>
          ),
          data: (
            <>
              <div className="d-md-block d-none">
                {/* <HeaderSectionDark data={{ name: "Cap Table (Current)" }} /> */}
              </div>
              {status ? (
                <div>

                  <SharesSelectMobileModule />

                  <SharesChartLineModule />

                  <WebbDividerSmall />
                  <SharesValuationCardModule />

                  <WebbDividerMedium />
                  <SharesTableTeamModule />

                  <FloatButton action={SharesUserActionsModule} />
                </div>
              ) : (
                <p>Please Initiate assets first</p>
              )}
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <SharesUserActionsModule />
            </>
          ),
          footer: <></>,
        }}
      />
    </>
  );
}

// <WebbDividerMedium />
// <WebbDividerMedium />
// <p className='text-bold d-none'>What is a Common Stock? </p>
// <p className='d-none'>Common stock is a type of security that represents ownership of equity in a company. There are other terms – such as common share, ordinary share, or voting share – that are equivalent to common stock.</p>
// <p className='d-none'>Holders of common stock own the rights to claim a share in the company’s profits and exercise control over it by participating in the elections of the board of directors, as well as in voting regarding important corporate policies. Common stock owners can profit from the capital appreciation of the securities. On average, common shares offer a higher return relative to preferred stock or bonds. However, the higher returns come with the higher risks associated with such securities.</p>

// <WebbDividerMedium />
// <p className='text-bold d-none'>What is a Preferred Stock? </p>
// <p className='d-none'>Preferred Stock (also known preference shares) are securities that represent ownership in a corporation, and that have a priority claim over common shares on the company’s assets and earnings. The shares are more senior than common stock but are more junior relative to bonds in terms of claim on assets. Holders of preferred stock are also prioritized over holders of common stock in dividend payments.</p>
