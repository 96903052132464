import { useEffect, useState } from "react";
import { Link, useHistory, useNavigate, useParams } from 'react-router-dom';

import WebbLoader from "../webx/webb-loader-sm";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbDividerMedium from "../webx/webb-divider-md";
import HeaderSectionDark from "../webx/head-section-dark";
import HeaderSectionLite from "../webx/head-section-lite";




import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";


import ContractOverview from "../webx/contract-overview";
import ContractActions from "./contract-actions";
import ContractMembers from "../webx/contract-members";
import ContractFiles from "../webx/contract-files";
import { ContractDetails, ContractDocumentList } from "../../services/srvc-contract-realm";


export default function ContractViewDetailsModule() {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true);

  const [info, setInfo] = useState({});
  const [mmbr, setMembers] = useState([]);
  const [file, setFiles] = useState([]);
  const [Pendingfiles, setPendingFiles] = useState([])
  const [canceledfiles, setCanceledfiles] = useState([])
  const [kycfiles,setKycfiles] = useState([])
  const [draftfiles,setDraftfiles] = useState([])

  const [draft,setDraft] = useState(false)
  const [completed, setCompleted] =useState(true)
  const [cancelled,setCancelled] = useState(false)
  const [processing, setProcessing] = useState(false)


 
  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        // info
        var res = await ContractDetails({data:{smart: id}, srvc:''})
        // console.log(res)
        if(res.data)
          {
            setInfo(res.data);
            var mmbx =res.data.members
            setMembers(mmbx)
          }


        // files
        var documents =await ContractDocumentList({data: {smart:id}, srvc:''})
        if(documents.stat) documents = documents.data
        // console.log(documents) 
        if(documents.length>0)
        {
          const completed_docs = documents.filter(function(item){ return item.stat=="6"})
          setFiles(completed_docs);
  
          const pending_files = documents.filter(function(item){ return item.stat=="2" && item.actv==true})
          // console.log(pending_files)
          setPendingFiles(pending_files)
  
          const draft_files = documents.filter(function(item){ return item.stat=="0" && item.actv==true})
          // console.log(pending_files)
          setDraftfiles(draft_files)
  
          var cancelled_files = documents.filter(function(item){ return item.stat=="11" && item.actv==false})
          setCanceledfiles(cancelled_files)

        }              

        const datax = {
          data: { document: id },
          user: asset.user,
          webb: process.env.REACT_APP_WEBB_SITE_NMBR
        }

        // const kycfiles =(await GetDocumentFiles(JSON.stringify(datax))).data
        // setKycfiles(kycfiles)
       
        // members
        
        //  (await GetDocumentMembers(JSON.stringify(datx))).data;
        

        setLoading(false);
      }
      fetchData()
    } else {}
  },[]);

  const HandleDocumentChange= async(item)=>{
    
    if(item=="draft"){setDraft(true);setCompleted(false);setCancelled(false);setProcessing(false)}
    if(item=="completed"){setDraft(false);setCompleted(true);setCancelled(false);setProcessing(false)}
    if(item=="cancelled"){setDraft(false);setCompleted(false);setCancelled(true);setProcessing(false)}
    if(item=="processing"){setDraft(false);setCompleted(false);setCancelled(false);setProcessing(true)}

  }

  if (loading){ return ( <> <WebbLoader /> </> ) }

  return (
  <>
    
    {/* <HeaderSectionDark data={{name: 'Overview'}}/>
    <ContractOverview data={info}/>
    <WebbDividerSmall /> */}
{/* 
    <ContractActions data={{docx: info, mmbr: mmbr.length, file: draftfiles.length}}/>
    <WebbDividerSmall/> */}


    {/* members */}
    <HeaderSectionDark data={{name: 'Members'}}/>
    <div className="">
    <p className="text-small text-color-tone">
      Please use the Members Option to add, remove and change the Document Signing Order
    </p>
    <ContractMembers data={mmbr} admin= {info.user}/>
    </div>

    {/* files */}
    <WebbDividerMedium />
    <HeaderSectionDark data={{name: 'Documents'}}/>
    <p className="text-small text-color-tone">
      Please use files option above to add files
    </p>

    <div className="back-color-wite rounded-wd ">

      <div className="p-3 border rounded-xd mb-2">
        <p className="">
          <i className="bi bi-circle-fill text-color-success small"></i>
          <span className="ms-1 text-bold">Completed</span>
        </p>
        <ContractFiles smartid= {id} data={file}/>  
      </div>
      
      <div className="p-3 border rounded-xd mb-2">
        <p className="">
          <i className="bi bi-circle-fill text-color-next small"></i>
          <span className="ms-1 text-bold">In Process</span>
        </p>
        <ContractFiles smartid= {id} data={Pendingfiles}/>
      </div>


      <div className="p-3 border rounded-xd mb-2">
        <p className="">
          <i className="bi bi-circle-fill text-warning small"></i>
          <span className="ms-1 text-bold">In Review</span>
        </p>
        <p className="text-color-tone">No Documents</p>
        <WebbDividerSmall/>
      </div>


      <div className="p-3 border rounded-xd mb-2">
        <p className="">
          <i className="bi bi-circle-fill text-color-tint small"></i>
          <span className="ms-1 text-bold">Draft</span>
        </p>
        <ContractFiles smartid= {id} data={draftfiles}/>
      </div>


      <div className="p-3 border rounded-xd">
        <p className="">
          <i className="bi bi-circle-fill text-danger small"></i>
          <span className="ms-1 text-bold">Cancelled</span>
        </p>
        <ContractFiles smartid= {id} data={canceledfiles}/>
      </div>


    </div>

    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
    <WebbDividerMedium />
  </>
  )
}