// network
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; 

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

const list = require('../../data.static/data-select-user.json').data.filter(item=>item.actv)


export default function UserSelectModule(props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx  

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const location = useLocation();

  const [data, setData] = useState([]);
  const [mode, setMode] = useState({item: location.pathname.split('/')[2]})
  const [sort, setSort] = useState({item: location.pathname.split('/')[3]})

  useEffect( () => {
    if (asset){
      const fetchData = async() => {
        setLoading(true);
        setData(list)
        // setData(list.filter(item => item.user.includes(asset.form)))
        setLoading(false);
      }
      fetchData()
    } else {}
  },[asset.item]);
  
  const handleChange = async(key, val) => {
    setSort({ ...sort, [key]: val });
  }

  const handleSelect = async(sort) => {
    // console.log('item: ', sort);
    handleCallback(sort);
  }

  const handleCallback = (srtx) => {
    props.item(srtx);
  }

  // if (loading){ return ( <> </> ) }

  return (
    <>
      <div className="rounded-wd back-color-wite">
      {data && data.length > 0 && data.map((item, i) => (
  
        <div className="p-1 border-bottom border-light">
  
          <div className={`d-flex cursor p-2 hilite rounded-wd ${ i < data.length-1 ? '': ''}`} key={i}
            // onClick={()=>{handleChange('item',item.name); handleCallback(item.name)}}
            onClick={()=>{setSort({'item':item.name}); navigate(`/${usxx}/${mode.item}/${item.link}`)}}
            
          >
          
            <div className="">
              <span className="text-small">
                <i className={`m-0 p-0 small bx bxs-circle text-${sort.item===item.link? 'color-success': 'color-lite'}`}></i>
              </span>
            </div>
            
            <div className="ms-2">
              <p className={`m-0 p-0 text-${sort.item===item.link? 'color-success text-bold': 'color-tone'}`}>
                {item.name}
              </p>
            </div>
  
            <div className="ms-auto">
              <i className={`m-0 p-0 text-color-tint bi-chevron-right`}></i>
            </div>
          
          </div>
          <div className="border-bottom border-light d-none"></div>
        </div>
      ))}
      </div>
    </>
    )
  }