// documents
import { Link, useParams } from "react-router-dom";

import WebbDividerSmall from "./webb-divider-sm";
import WebbDividerMedium from "./webb-divider-md";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';


export default function NetworkMembers(props) {

  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const { id } = useParams();
  
  const data = props.data
  console.log (data)

  if (data.length === 0) 
  return (
  <> 
    <span> No Contacts</span>
    <WebbDividerMedium/>
  </>
  )


  return (
  <>
    <div className={`rounded-wd back-color-wite border `}>
    {data && data.length > 0 && data.map((item, i) => (
      <div key={i}>
      
      <div className="d-flex p-3" >

        <div className="align-middle mt-1">
          <Jazzicon diameter={30} seed={jsNumberForAddress(item?.user?.item || Date.now())} />   
          {/* <Jazzicon diameter={30} id={item?.user?.mail || Date.now()} /> */}
        </div>
        

        <div className="align-middle ms-2">
          <p className="m-0 p-0 text-sm">
            <span className="text-bold">{item?.user?.name}</span>
            <span className="ps-2"></span>
            <span className={`p-1 px-2 small rounded-md back-color-tint text-color-tone ${item.item === asset.item ? '' : 'd-none'}`}>
              <small>{'YOU'}</small>
            </span>
          </p>
          <p className="m-0 p-0 text-small text-sm">{item?.user?.mail}</p>
          
        </div>

        <div className={`ms-auto py-2 text-end`}>
          
          <div 
            className={''}
          > 
          <i class={`bx bxs-circle text-${item.active ? "success": "warning" }`}></i>
          </div>
          
        </div>


      </div>
      <div className={`border-bottom ${i < data.length-1 ? '' :'d-none'}`}></div>
      </div>
      ))}
    </div>

    <WebbDividerSmall/>
   
  </>
  )
}