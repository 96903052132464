// dashboard shortcuts
import { Link } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

const list = require("../../data.static/data-user-tasks.json").data;

export default function UserTasksModule() {

  const usxx = GetUserForm();
  const asset = GetUserForm() === "user" ? GetLocalUser() : GetLocalBusiness();
  const data = (list.filter(item => item.user.includes(asset.role)));

  return (
  <>

    <WebbDividerSmall />
    <div className="text-small">
      <div className="row row-cols-3 g-1">
        {data && data.map((item, i) => (
        
        <div className="col text-center" key={i}>

          <Link 
            to={`${item.link}`}       
            style={{pointerEvents:`${ item.actv ? '' : 'none' } `}}
            className={`w-100 h-100 border-none text-center
            text-decoration-none m-0 p-0 ${item.actv ? '' : 'text-color-tone'}`}>

            <div className="p-1 py-2 rounded-wd back-color-wite hitone">              
              <i className={`m-0 p-0 ${item.icon}`}  
                style={{fontSize:"2em", lineHeight:"0"}}>
              </i>
              
              <div className="d-none d-md-block">
                <p className={`small m-0 p-0 pt-1 text-dark text-nowrap`}>
                  {item.name}
                </p>
              </div>
              
              <div className="d-md-none">
                <p className={`small m-0 p-0 pt-1 text-dark text-nowrap`}>
                  <small>{item.name}</small>
                </p>
              </div>

            </div>            
          </Link>

        </div>
        ))}

      </div>
    </div>

  </>
  )
}