// user account
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

import { NumberFormat, CardNumberFormat } from "../../services/srvc-utilities";

import WebbDividerMedium from "../webx/webb-divider-md";
import WebbDividerSmall from "../webx/webb-divider-sm";
import WebbIcon from "../webx/webb-icon";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import FormNeeded from "../webx/form-needed";
import { TeamAccounCreate, TeamMemberAdd, UserAccountList } from "../../services/srvc-users-realm";
import { AccountsList } from "../../services/srvc-accounts-realm";
import WebbLoaderSmall from "../webx/webb-loader-sm";

// import { AccountsBaseBalance } from "../../services/srvc-accounts-realm";

// const media = 'https://img.freepik.com/free-vector/abstract-glowing-particles-background-blue-red-color_1017-26575.jpg?w=900'
const media = 'https://img.freepik.com/free-photo/blockchain-technology-background-gradient-blue_53876-124646.jpg?w=300'

export default function TeamAccountListModule () {

  
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  // console.log(asset)
  
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([])


  useEffect( () => {
    setLoader(true)
    if (asset){
      const fetchData = async() => {
        setLoader(true);
        var res = await UserAccountList({data:{user:asset.item}, srvc:''})
        // console.log(res)
        if(res.stat){
           var teams =  res.data.list.filter(item => item.role=="team")
           setData(teams)
        }
        
        setLoader(false);
      }
      fetchData()
    } else {}
  },[]);

  if(loader) return (<div className="text-center mt-4"> <WebbLoaderSmall /> </div> )

  if(!loader && data.length==0) return( <>No team found</>)


  return (
  <>
    {/* info */}

    {/* data */}
    <div className="rounded-none  p-3 bg-white">
      {
        data && data.length>0 && data.map(item => {
            return(
                <div className="border rounded-xd p-2 mb-2">
                <div className="d-flex  ">
                    <div className="">
                        <Jazzicon diameter={35} />
                    </div>
                    <div className="me-auto mx-3">
                        <p className="mt-1">{item.name}</p>
                    </div>
                    <div className="text-end">
                    <i class={`bx bxs-circle mt-2 text-end text-${item.active ? 'success': ''}`}></i>
                    </div>

                </div>
                </div>
            )

        })
      }
      
        
    

    </div>

    

  </>

  )
}